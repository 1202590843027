<template>
  <div >


    <div class="md-card md-theme-default mt-8  shadow-0">
      <div class="md-card-content md-card-table">
        <div>
          <div class="d-flex justify-space-between align-center ">
            <SvgIcon class="text-2xl font-semibold" text="Members" >
            </SvgIcon>
            <v-spacer/>


            <v-btn
                v-if="checkExportPermission($modules.memberships.management.slug)"
                class="export-button mt-2"
                elevation="0"
                height="40"
                @click="exportData"
            >
              <SvgIcon text="Export" >
                <template v-slot:icon>
                  <ExportIcon/>
                </template>
              </SvgIcon>


            </v-btn>

            <v-btn
                height="40"
                class="mt-2 shadow-0 ml-2"
                v-if="
                          checkWritePermission(
                            $modules.memberships.management.slug
                          ) && membershipStatus == 1
                        "
                @click="addMemberPopup"
                color="#00b0af"
                dark
            >
              <AddIcon/> Add Member
            </v-btn>




          </div>

          <br><br/>
          <div class="table-responsive">
            <table class="table border-collapse ">
              <thead>
              <tr class="opacity-70 tr-neon tr-rounded ">
                <th >
                  <div class="" style="min-width: 100px">Name</div>
                  <div class="search_column">
                    <v-autocomplete
                        solo
                        filled
                        label="Name"
                        v-model="searchParams.name"
                        item-value="name"
                        item-text="name"
                        :items="names"
                        :loading="isLoading"
                        :search-input.sync="name"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        hide-details
                        flat
                    ></v-autocomplete>
                  </div>
                </th>
                <th class="">
                  <div class="" style="min-width: 80px">Tag</div>
                  <div class="search_column">
                    <v-autocomplete
                        solo
                        filled
                        label="Tag"
                        v-model="searchParams.tag"
                        item-value="tag"
                        item-text="tag"
                        :items="tags"
                        :loading="isLoading"
                        :search-input.sync="tag"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        hide-details
                        flat
                    ></v-autocomplete>
                  </div>
                </th>

                <th class="">
                  <div class="" style="min-width: 150px">Mobile</div>
                  <div class="search_column">
                    <v-autocomplete
                        solo
                        filled
                        label="Mobile"
                        v-model="searchParams.mobile"
                        item-value="mobile"
                        item-text="mobile"
                        :items="phones"
                        :loading="isLoading"
                        :search-input.sync="phone"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        hide-details
                        flat
                    ></v-autocomplete>
                  </div>
                </th>
                <th class="">
                  <div class="" style="min-width: 150px">Email</div>
                  <div class="search_column">
                    <v-autocomplete
                        solo
                        filled
                        label="Email"
                        v-model="searchParams.email"
                        item-value="email"
                        item-text="email"
                        :items="emails"
                        :loading="isLoading"
                        :search-input.sync="email"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        hide-details
                        flat
                    ></v-autocomplete>
                  </div>
                </th>
                <th class="">
                  <div

                      @click="sortColumn('start_date')"
                  >
                    <div class="cursor-pointer"
                         style="width: 150px"

                    >
                      Start Date
                      <v-icon class="ml-2"  small>
                        mdi-sort{{
                          orderBy == "start_date"
                              ? "-" +
                              (orderDir == "ASC"
                                  ? "ascending"
                                  : "descending")
                              : ""
                        }}
                      </v-icon>
                    </div>
                    <div class="search_column">
                      <date-menu
                          v-model="searchParams.start_date"
                          @change="getMembersList()"
                          label="Start Date"
                          class-name="q-text-field shadow-0"
                          :hide-details="true"
                          :flat="true"
                      >
                      </date-menu>
                    </div>
                  </div>
                </th>
                <th class="">
                  <div
                      style="min-width: 80px"

                      @click="sortColumn('end_date')"
                  >
                    <div class=" cursor-pointer" style="min-width: 120px">
                      End Date
                      <v-icon class="ml-2"  small>
                        mdi-sort{{
                          orderBy == "end_date"
                              ? "-" +
                              (orderDir == "ASC"
                                  ? "ascending"
                                  : "descending")
                              : ""
                        }}
                      </v-icon>
                    </div>
                    <div class="search_column">
                      <date-menu
                          v-model="searchParams.end_date"
                          @change="getMembersList()"
                          label="End Date"
                          class-name="q-text-field shadow-0"
                          :hide-details="true"
                          :flat="true"
                      >
                      </date-menu>
                    </div>
                  </div>
                </th>
                <th class="">
                  <div class="" style="min-width: 140px">Card No</div>
                  <div class="search_column">
                    <v-text-field
                        solo
                        filled
                        label="Card ID"
                        clearable
                        v-model="searchParams.card_number"
                        append-icon="mdi-credit-card-search"
                        @click:append="getMembersList()"
                        @click:clear="
                                  () => {
                                    searchParams.card_number = null;
                                    getMembersList();
                                  }
                                "
                        class="q-text-field shadow-0"
                        hide-details
                        flat
                    ></v-text-field>
                  </div>
                </th>
                <th class="" style="min-width: 200px !important;">
                  <div class="">
                    Display Number
                  </div>
                  <div class="search_column">
                    <v-text-field
                        solo
                        filled
                        label="Display Card ID"
                        clearable
                        v-model="searchParams.display_card_number"
                        append-icon="mdi-credit-card-search"
                        @click:append="getMembersList()"
                        @click:clear="
                                  () => {
                                    searchParams.display_card_number = null;
                                    getMembersList();
                                  }
                                "
                        class="q-text-field shadow-0"
                        hide-details
                        flat
                    ></v-text-field>
                  </div>
                </th>
                <th class="">
                  <div class="" style="min-width: 110px">Package</div>
                  <div class="search_column" style="">
                    <v-select
                        label="Packages"
                        :items="[
                                  { id: null, name: 'All' },
                                  { divider: true },
                                  ...packages,
                                ]"
                        item-text="name"
                        item-value="id"
                        v-model="searchParams.membership_package_id"
                        @change="getFilterData"
                        filled
                        solo
                        class="q-autocomplete shadow-0 packages"
                        hide-details
                        flat
                    >
                      <template slot="item" slot-scope="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ data.item.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ data.item.type }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-select>
                  </div>
                </th>
                <th class="" style="position: relative">
                  <div class="" style="min-width: 100px">Status</div>
                  <div class="search_column">
                    <v-select
                        solo
                        filled
                        :items="[
                                  { name: 'All', status: null },
                                  { name: 'Active', status: 1 },
                                  { name: 'Inactive', status: 2 },
                                  { name: 'Expired', status: 10 },
                                  { name: 'Unpaid', status: 5 },
                                  { name: 'Cancelled', status: 13 },
                                  { name: 'Freezed', status: 28 },
                                ]"
                        item-text="name"
                        label="Status"
                        v-model="searchParams.status_id"
                        item-value="status"
                        @change="getFilterData()"
                        class="q-autocomplete shadow-0"
                        hide-details
                        flat
                    ></v-select>
                  </div>
                </th>
                <th
                    class=""
                    v-if="
                            checkWritePermission(
                              $modules.memberships.management.slug
                            )
                          "
                >
                  <div class="">Installment</div>

                </th>
                <th
                    class=""
                    v-if="
                            checkWritePermission(
                              $modules.memberships.management.slug
                            )
                          "
                >
                  <div class="">Details</div>
                </th>
                <th
                    class=""
                    v-if="
                            checkWritePermission(
                              $modules.memberships.management.slug
                            )
                          "
                >
                  <div class="">Card</div>

                </th>
                <th
                    class=""
                    v-if="
                            checkWritePermission(
                              $modules.memberships.management.slug
                            )
                          "
                >
                  <div class="">Renew</div>

                </th>

                <th class="">
                  <div class="">History</div>

                </th>
                <th
                    class=""
                    v-if="
                            checkWritePermission($modules.clients.customers.id)
                          "
                >
                  <div class="">Edit</div>

                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(member, index) in membersList"
                  :class="calculateClass(index)"
                  :key="member.id"
                  :style="{
                          'background-color':
                            member.status == 'Expired'
                              ? 'rgb(255 0 0 / 55%)'
                              : member.about_to_expire
                              ? 'rgb(255 0 0 / 20%)'
                              : 'white',
                        }"
              >
                <td
                    style="max-width: 150px; overflow: hidden; text-overflow: ellipsis;"
                >
                  <span class="text_ellipse text-neon text-base pointer font-bold"   @click="showUserModel(member.customer_id)">{{ member.first_name }}
                      {{ member.last_name || "" }} </span>

                </td>

                <td >
                  <v-btn
                      v-if="member.tag_name"
                      :color="member.tag_color_code"
                      height="28"
                      dark
                  >{{ member.tag_name }}</v-btn
                  >
                  <span v-else>N/A</span>
                </td>
                <td >
                  <div >
                    {{ member.mobile }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ member.email }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ member.start_date | dateformat }}
                  </div>
                </td>
                <td >
                  <div >
                    <v-btn
                        v-if="!disableStatus.includes(member.status)"
                        @click="getExpiryDate(member)"
                        small
                        color="#00b0af"
                        dark
                    >{{ member.end_date | dateformat }}</v-btn
                    >
                    <span v-else>{{
                        member.end_date | dateformat
                      }}</span>
                  </div>
                </td>
                <td >
                  <div >
                    {{ member.card_number || "NA" }}
                  </div>
                </td>
                <td >
                  <div >
                    <v-btn
                        v-if="member.status != 'Expired'"
                        @click="getDisplayNumber(member)"
                        small
                        color="#00b0af"
                        dark
                    >{{ member.display_card_number || "NA" }}</v-btn
                    >
                    <span v-else>{{
                        member.display_card_number || "NA"
                      }}</span>
                  </div>
                  <div ></div>
                </td>
                <td >
                  <div >
                    {{ member.package_name }}
                  </div>
                </td>
                <td

                    v-if="
                            !isEnableApprovalProcess &&
                              (member.status == 'Inactive' ||
                                member.status == 'Pending')
                          "
                >
                  <div >
                    <v-btn
                        v-if="member.status == 'Unpaid'"
                        @click="getOrderDetails(member)"
                        small
                        color="#00b0af"
                        dark
                    >Pay</v-btn
                    >
                    <v-menu v-else left :offset-y="true">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="#00b0af"
                            height="28"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >{{ member.status }}</v-btn
                        >
                      </template>
                      <v-list>
                        <v-list-item
                            @click="
                                    membershipApproval(member, 'Activate')
                                  "
                        >
                          <v-list-item-title>
                            Activate
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            Cancel
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </td>

                <td

                    v-else-if="
                            member.status != 'Inactive' &&
                              member.status != 'Pending'
                          "
                >
                  <div >
                    <v-btn
                        v-if="member.status == 'Unpaid'"
                        @click="getOrderDetails(member)"
                        small
                        color="#00b0af"
                        dark
                    >Pay</v-btn
                    >
                    <span v-else>{{ member.status }}</span>
                  </div>
                </td>
                <td  v-else>
                  <v-menu left :offset-y="true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          color="#00b0af"
                          height="28"
                          dark
                          v-bind="attrs"
                          v-on="on"
                      >{{ member.status }}</v-btn
                      >
                    </template>
                    <v-list>
                      <v-list-item
                          @click="membershipApproval(member, 'Activate')"
                      >
                        <v-list-item-title>
                          Activate
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title> Cancel </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>

                <td

                    v-if="
                            checkWritePermission(
                              $modules.memberships.management.slug
                            )
                          "
                >
                  <v-btn
                      @click="getOrderDetails(member)"
                      small
                      color="#00b0af"
                      dark
                  >{{ member.paid_installments }}/{{
                      member.installments
                    }}</v-btn
                  >
                </td>

                <td

                    v-if="
                            checkWritePermission(
                              $modules.memberships.management.slug
                            )
                          "
                >
                  <v-btn
                      dark
                      small
                      color="#066a8c"
                      :disabled="
                              member.status == 'Cancelled' ||
                                member.status == 'Refund' ||
                                member.status == 'Void'
                            "
                      @click="
                              populateMembershipDetails(member.order_id, 'edit')
                            "
                  >Details</v-btn
                  >
                </td>
                <td

                    v-if="
                            checkWritePermission(
                              $modules.memberships.management.slug
                            )
                          "
                >
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-bind="attrs"
                          v-on="on"
                          dark
                          small
                          color="#066a8c"
                          :disabled="
                                  member.status == 'Cancelled' ||
                                    member.status == 'Refund' ||
                                    member.status == 'Void'
                                "
                      >View</v-btn
                      >
                    </template>
                    <v-list>
                      <v-list-item @click="viewMembershipCard(member)">
                        <v-list-item-title>View Card</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                          v-if="member.status != 'Inactive'"
                          @click="sendMembershipCard(member)"
                      >
                        <v-list-item-title>Send Card</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                          v-if="member.status != 'Inactive'"
                          @click="
                                  membershipDeactivate(member, 'Deactivate')
                                "
                      >
                        <v-list-item-title
                        >Deactivate Card</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                          v-if="
                                  member.status == 'Active' &&
                                    checkWritePermission(
                                      $modules.memberships.upgrade.slug
                                    )
                                "
                          @click="upgradeMembership(member)"
                      >
                        <v-list-item-title
                        >Upgrade Membership</v-list-item-title
                        >
                      </v-list-item>

                      <v-list-item
                          v-if="
                                  member.status != 'Inactive' &&
                                    member.status == 'Active' &&
                                    checkWritePermission(
                                      $modules.memberships.freeze.slug
                                    )
                                "
                          @click="membershipFreeze(member, 'Freeze')"
                      >
                        <v-list-item-title
                        >{{ member.freeze_start ? "Un" : "" }}Freeze
                          Membership</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                          v-if="
                                  member.status == 'Freezed' &&
                                    checkWritePermission(
                                      $modules.memberships.freeze.slug
                                    )
                                "
                          @click="membershipFreeze(member, 'UnFreeze')"
                      >
                        <v-list-item-title
                        >UnFreeze Membership</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                          v-if="
                                  member.additional_price && member.additional_price >= 0 && member.status === 'Active'
                                "
                          @click="addAdditionalMember(member)"
                      >
                        <v-list-item-title
                        >Add additional member</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <td

                    v-if="
                            checkWritePermission(
                              $modules.memberships.management.slug
                            )
                          "
                >
                  <v-btn
                      dark
                      small
                      color="#066a8c"
                      :disabled="
                              member.status == 'Cancelled' ||
                                member.status == 'Refund' ||
                                member.status == 'Void'
                            "
                      @click="
                              populateMembershipDetails(
                                member.order_id,
                                'renew'
                              )
                            "
                  >Renew</v-btn
                  >
                </td>

                <td >
                  <v-icon
                      @click="getRenewHistory(member.customer_id)"
                      class="pl-2"
                      color="#066a8c"
                  >mdi-history</v-icon
                  >
                </td>
                <td

                    v-if="
                            checkWritePermission($modules.clients.customers.id)
                          "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          @click="editCustomer(member.id)"
                          class="pl-2"
                          color="#112A45FF"
                      >mdi-pencil</v-icon
                      >
                    </template>
                    Edit
                  </v-tooltip>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-pagination v-model="page" :length="totalPages" class="new-pagination" total-visible="7"></v-pagination>
      </v-col>
      <v-col class="d-flex align-center justify-end" cols="4">
        <div class="d-flex align-center justify-end text-sm gap-x-2">
                <span class="">
                  Result
                </span>
          <div style="width: 80px">
            <v-select
                v-model="perPage"
                :items="[10, 15, 25, 50]"
                :menu-props="{ bottom: true, offsetY: true }"
                class="q-autocomplete text-sm"
                flat
                hide-details
                solo
                @change="getFilterData"
            ></v-select>
          </div>
          <span>Per Page</span>
        </div>
      </v-col>
    </v-row>



    <v-dialog v-model="membershipDialoge" scrollable width="760px">
      <v-form ref="form"  v-model="valid">
      <v-card>
        <v-card-text>
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" text=" Membership Registrations" style="color: black" >
                </SvgIcon>
                <v-btn  fab x-small class="shadow-0"   @click="
              (membershipDialoge = false),
                (editFlag = false),
                (renewOrEdit = false)
            ">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <v-row>
            <v-col md="12">
              <div class="font-semibold text-base text-blue">Package details</div>
            </v-col>
          </v-row>
          <v-divider class="mt-2 "/>
          <v-row class="mt-2">
            <v-col cols="6">
              <label for="">Packages</label>
              <v-select
                  :items="packages"
                  return-object
                  item-text="name"
                  item-value="id"
                  v-model="member.package"
                  @change="checkPackageHasPricing()"
                  outlined
                  :rules="[(v) => !!v || 'Package is required']"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :disabled="editFlag"
                  class="q-autocomplete shadow-0"
                  hide-details
                  dense
                  validate-on-blur
              >
                <template slot="item" slot-scope="data">
                  <template v-if="!(typeof data.item == 'object')">
                    <v-list-item-content style="text-align: center">
                      {{ data.item }}
                    </v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data.item.type }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
            </v-col>
            <v-col cols="6">
              <label for="Plan">Plan</label>

              <v-select
                  :items="plans"
                  item-text="name"
                  item-value="id"
                  v-model="member.plan"
                  @change="memberPackagePrice()"
                  outlined
                  :rules="[(v) => !!v || 'Plan is required']"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :disabled="editFlag"
                  class="q-autocomplete shadow-0"
                  hide-details
                  dense
                  validate-on-blur
              >
              </v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <label for="">Start Date</label>
              <date-field
                  v-model="member.start_date"
                  @change="setEndDate('member')"
                  :rules="[(v) => !!v || 'Package is required']"
                  :backFill="
                      checkBackfillPermission(
                        $modules.memberships.management.slug
                      )
                    "
                  class-name="q-text-field shadow-0"
                  :hide-details="true"
                  :dense="true"
                  bg-color=""
                  label=""

              ></date-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <label for="">End Date</label>

              <date-field
                  :disabled="member.package == null"
                  :min="member.start_date ? member.start_date : today"
                  v-model="member.end_date"
                  :rules="[(v) => !!v || 'Package is required']"
                  @input="menu2 = false"
                  :backFill="
                      checkBackfillPermission(
                        $modules.memberships.management.slug
                      )
                    "
                  class-name="q-text-field shadow-0"
                  :hide-details="true"
                  bg-color=""
                  :dense="true"
                  label=""
              ></date-field>
            </v-col>
            <v-col sm="6">
              <label for="">Tag</label>

              <v-select
                  v-model="member.tag"
                  :items="membershipTags"
                  item-text="name"
                  item-value="id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  class="q-autocomplete shadow-0"
                  hide-details
                  dense
                  validate-on-blur
              ></v-select>
            </v-col>

            <v-col
                sm="6"
                v-if="checkReadPermission($modules.salesTeam.management.slug)"
            >
              <label for="">Sales Team</label>
              <v-select
                  v-model="member.sales_team_id"
                  :items="salesTeams"
                  item-text="name"
                  item-value="id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  class="q-autocomplete shadow-0"
                  hide-details
                  dense
                  validate-on-blur
                  :disabled="editFlag && !checkBackfillPermission(
                        $modules.memberships.management.slug
                      )"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="documentFields.length">
            <div class="col-md-12">
              <p class="font-semibold text-base text-blue">Documents</p>
            </div>
            <v-divider class="mb-4"></v-divider>
            <div class="col-md-12">
              <v-card
                  :style="cardStyle"
                  class="pt-3 pl-2 pr-2 mt-8"
                  elevation="0"
                  v-if="documentFields.length"
              >
                <v-row>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-for="(documentField, index) in documentFields"
                      :key="index"
                  >
                    <v-file-input
                        :label="documentField.name"
                        v-model="documentField.file"
                        prepend-inner-icon="mdi-paperclip"
                        prepend-icon
                        outlined
                        background-color="#fff"
                        :rules="documentUploadRule(documentField)"
                    >
                      <template v-slot:prepend-inner>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                                color="cyan"
                                v-if="
                                documentField.document_id &&
                                  documentField.file_path
                              "
                                @click="openFile(documentField.file_path)"
                                v-on="on"
                            >
                              mdi-download-box
                            </v-icon>
                            <v-icon v-else v-on="on"> mdi-paperclip </v-icon>
                          </template>
                          <span
                              v-if="
                              documentField.document_id &&
                                documentField.file_path
                            "
                          >Download uploaded file</span
                          >
                          <span v-else>Upload Document</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:label>
                        <span v-if="!documentField.isEdit">
                          {{ documentField.name }}
                        </span>
                        <span
                            v-if="
                            documentField.isEdit == true &&
                              documentField.file == null &&
                              documentField.original_file_name
                          "
                            class="font-weight-bold"
                        >
                          <span
                              style="width: 70%; display: inline-block"
                              class="text-truncate"
                          >{{ documentField.original_file_name }}</span
                          >
                          <span
                              style="width: 20%; display: inline-block"
                              class="text-truncate"
                          >.{{
                              documentField.original_file_name.split(".")[
                              documentField.original_file_name.split(".")
                                  .length - 1
                                  ]
                            }}</span
                          >
                        </span>
                      </template>
                      <template
                          v-slot:append
                          v-if="documentField.uploaded_file_path"
                      >
                        <v-btn
                            @click="openFile(documentField.uploaded_file_path)"
                            class="text-truncate"
                            depressed
                            color="primary"
                            text
                            style="transform: translate(0, -22%)"
                        >
                          Download {{ documentField.name }}
                        </v-btn>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-row>

            <v-row class="mt-2" >
              <div class="col-md-12">
                <div class="d-flex align-center justify-space-between">
                  <div class="font-semibold text-base text-blue">Member details</div>
                </div>
              </div>
            </v-row>
            <v-card
                class="mt-8 bordered"
                elevation="0"
                v-for="(customer, index) in member.customers"
                :key="index"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      fab
                      x-small
                      absolute
                      top
                      right
                      dark
                      @click="deleteCustomer(index)"
                      v-if="member.customers.length > 1 && !customer.member_id"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                Delete
              </v-tooltip>
              <v-card-actions style="background-color: rgba(248, 250, 251, 1)"  v-if="member.customers.length > 1"  >
                <p class="text-lg font-bold blue-text">Member {{ index + 1 }}</p>
              </v-card-actions>
              <v-card-text>
                <v-row v-if="!member.order_id" dense class="mb-4">
                  <v-col md="12">
                    <div class="d-flex justify-space-between" >
                      <div class="d-flex"  v-if="!member.order_id">
                        <card-reader-button
                            className=" mt-5 text-blue text-capitalize light-blue-color"

                            label="Samsotech Reader"
                            docType="chip"

                            @data="
                          (data) => {
                            setCardData(data, index);
                          }
                        "
                        ></card-reader-button>
                        <card-data-button
                            className="mt-5 ml-4 text-blue text-capitalize light-blue-color"
                            label="HID Omnikey"
                            @data="
                          (data) => {
                            setCardData(data, index);
                          }
                        "
                        ></card-data-button>
                      </div>


                    </div>
                  </v-col>


                </v-row>
                <v-row dense align="center">
                  <v-col md="3">
                    <div style="aspect-ratio: 1/1 ">
                      <image-uploader
                          @upload="
                          (data) => {
                            customer.image = data;
                          }
                        "
                          @remove="
                          () => {
                            customer.image = null;
                          }
                        "
                          :image_path="customer.image_path"
                          :height="240"
                          defaultImage="user"
                          :show-guide="false"
                          message-text=""
                          text=""
                      ></image-uploader>
                    </div>
                  </v-col>

                  <v-col md="9">
                    <v-row dense>
                      <v-col md="12">
                        <label for="">Mobile Number*</label>
                        <v-mobile-search
                            v-model="customer.search"
                            :selected="customer.mobile"
                            @updateCustomer="setCustomerData($event, index)"
                            ref="mobile"
                            hide-details="auto"
                            class-name1="q-text-field shadow-0"
                            class-name2="q-text-field shadow-0 mobile_auto_complete_hide_anchor"
                            background-color=""
                            :dense="true"
                            label=""
                            :show-label="false"
                            :dial-code-grid-size="3"
                        ></v-mobile-search>
                      </v-col>
                      <v-col md="12">
                        <label for="">Customer Name*</label>
                        <v-name-search
                            :selected="customer.name"
                            :mobile="customer.mobile"
                            :email="customer.email"
                            v-model="customer.nameSearch"
                            @updateCustomer="setCustomerData($event, index)"
                            ref="name"
                            hide-details="auto"
                            class-name="q-text-field shadow-0 mobile_auto_complete_hide_anchor"
                            background-color=""
                            :dense="true"
                            label=""
                        ></v-name-search>
                      </v-col>

                    </v-row>
                  </v-col>

                </v-row>
                <v-row dense>
                  <v-col md="6">
                    <label for="">Email{{field.email.is_required ? '*' : ''}}</label>
                    <v-text-field
                        v-model="customer.email"
                        outlined
                        :readonly="existingUser || customer.customer_id != null"
                        required
                        :rules="emailRule"
                        hide-details="auto"
                        class="q-text-field shadow-0"
                        background-color=""
                        dense
                        validate-on-blur
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" v-if="field.dob.is_visible">
                    <label for="">Date Of Birth{{field.dob.is_required ? '*' : ''}}</label>
                    <date-of-birth-field
                        v-model="customer.dob"
                        :rules="dobRule()"
                        :dense="true"
                    >
                    </date-of-birth-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="field.nationality.is_visible"
                  >
                    <label for="">Nationality{{ field.nationality.is_required ? '*' : ''}}</label>
                    <v-autocomplete
                        v-model="customer.country_id"
                        :items="countries"
                        :rules="nationalityRule"
                        item-value="id"
                        item-text="name"
                        outlined
                        hide-details="auto"
                        class="q-autocomplete shadow-0"
                        dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="field.gender.is_visible"
                  >
                    <label for="">Gender</label>
                    <v-select
                        v-model="customer.gender"
                        :items="['Male', 'Female']"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        hide-details="auto"
                        class="q-autocomplete shadow-0"
                        dense
                        :rules="genderRule"
                        required
                    ></v-select>
                  </v-col>
                  <v-col sm="6" md="6" v-if="field.idProof.is_visible">
                    <label for="">ID Type{{field.idProof.is_required ? '*' : ''}}</label>
                    <v-select
                        v-model="customer.id_proof_type_id"
                        :rules="idTypeRule"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        item-value="id"
                        item-text="name"
                        :items="idProofTypes"
                        @change="changeIdProofTypeId($event, index)"
                        hide-details="auto"
                        class="q-autocomplete shadow-0"
                        dense
                    ></v-select>
                  </v-col>
                  <v-col cols="6" v-if="field.idProof.is_visible">
                    <label for="">{{customer.id_proof_path ? 'Download' : 'ID Proof'}} {{field.idProof.is_required ? '*' : ''}}</label>
                    <v-file-input
                        :rules="idProofRule"
                        v-model="customer.id_proof"
                        outlined
                        hide-details="auto"
                        class="q-text-field shadow-0"
                        dense
                        prepend-inner-icon="mdi-paperclip"
                        prepend-icon
                    >
                      <template v-slot:append>
                        <v-chip
                            class="text-truncate"
                            v-if="
                              !customer.id_proof &&
                                customer.id_proof_path != null
                            "
                            target="_blank"
                            :href="s3BucketURL + customer.id_proof_path"
                            style="width: 97%; transform: translate(0, -18%)"
                        >
                          <v-icon left>mdi-file-download-outline</v-icon>
                          {{ customer.id_proof_file_name }}
                        </v-chip>
                      </template>
                    </v-file-input>
                    <!-- <v-chip
                      v-if="customer.id_proof_path != null"
                      target="_blank"
                      :href="s3BucketURL + customer.id_proof_path"
                    >
                      <v-icon left>mdi-file-download-outline</v-icon>
                      {{ customer.id_proof_file_name }}
                    </v-chip> -->
                  </v-col>
                  <v-col sm="6" md="6" v-if="field.idProof.is_visible">
                    <label for="">ID Number {{field.idProof.is_required ? '*' : ''}}</label>
                    <v-text-field
                        v-model="customer.id_proof_number"
                        :rules="idTypeRule"
                        outlined
                        hide-details="auto"
                        class="q-text-field shadow-0"
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6" md="6" v-if="field.tag.is_visible">
                    <label for="">Tags{{field.tag.is_required ? '*' : ''}}</label>
                    <v-select
                        :items="allTags"
                        outlined
                        item-value="id"
                        item-text="name"
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-model="customer.customer_tag"
                        :rules="tagRule"
                        return-object
                        multiple
                        hide-details="auto"
                        class="q-autocomplete shadow-0"
                        dense
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col md="12">
                    <div class="d-flex gap-x-5">
                      <span class="d-flex align-center"><v-checkbox :ripple="false"  v-model="customer.opt_marketing"/> Opt In Marketing</span>
                      <v-switch
                          true-value="1"
                          false-value="0"
                          label="Primary Member"
                          v-model="customer.is_primary"
                          class="text-right"
                      ></v-switch
                      >
                    </div>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
            <!-- member.package != null && (member.package.members_limit > member.customers.length || member.package.additional_price != null) -->

          <v-row v-if="allowAdditionalMember" class="mt-3">
            <v-col md="12">
              <v-btn color="teal-color shadow-0"  dark @click="addNewCustomer">
               +Add Member
              </v-btn>
            </v-col>
          </v-row>



        </v-card-text>
        <div class="col-md-12s">
          <div class="d-flex justify-space-between" style="background-color: rgba(231, 234, 236, 1) ; padding: 4px">
            <div class="d-flex align-center text-xl font-bold m-l-4">
              {{
                discount_applied &&
                discounted_price != undefined &&
                member.price != null
                    ? "Price: " + currencyCode +' '+ discounted_price
                    : member.price != undefined
                        ? "Price:  " +
                        currencyCode +
                        (parseFloat(member.price) + parseFloat(member.tax))
                        : "Price:" + currencyCode + " 0"
              }}

              <span
                  class="text-decoration-line-through"
                  v-if="discount_applied && member.price != null"
              >
              {{
                  currencyCode +
                  (parseFloat(member.price) + parseFloat(member.tax))
                }}</span>
            </div>
            <div class="d-flex align-center">
              <v-autocomplete
                  v-if="promotions.length > 0 && member.price != null && !paidFlag"
                  :items="[{ name: 'No Promotion', promotion_code: null }, ...promotions]"
                  item-text="name"
                  item-value="promotion_code"
                  v-model="member.promotion_code"
                  background-color="rgb(206, 168, 0)"
                  outlined
                  :readonly="renewOrEdit"
                  @change="verifyBenefit('promotion')"
                  class="q-autocomplete shadow-0"
                  hide-details="hide-details"
                  placeholder="Promotion"
                  dense
              >
              </v-autocomplete>
            </div>

          </div>
        </div>

        <v-card-actions>
          <v-spacer/>
          <v-btn
              color=" "
              class="ma-2  "
              text
              @click="
              (membershipDialoge = false),
                (editFlag = false),
                (renewOrEdit = false)
            "
          > Close </v-btn>
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              @click="addOrUpdateMember"
              v-if="member.operation == 'add' || member.operation == 'renew'"
          >Save</v-btn
          >
          <v-btn
              v-else
              color=" darken-1"
              class="ma-2 white--text blue-color"
              @click="addOrUpdateMember"
          >Update Membership</v-btn
          >
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="historyDialoge" scrollable width="900">
      <v-card>
        <v-card-title class="headline">Membership History</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <table class="history_table">
                <thead>
                <th>Timestamp</th>
                <th>Package</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th>Price</th>
                <th>Receipt</th>
                </thead>

                <tr
                    v-for="(history, index) in historyData"
                    :key="index"
                    class="text-center"
                >
                  <td>{{ history.timestamp | timeStamp }}</td>
                  <td>{{ history.package }}</td>
                  <td>{{ history.start_date | dateformat }}</td>
                  <td>{{ history.end_date | dateformat }}</td>
                  <td>{{ statusText(history.status_id) }}</td>
                  <td>{{ Number(history.price) | toCurrency }}</td>
                  <td>
                    <v-btn small @click="showReceipt(history.order_id)">{{
                        history.status_id == 5 ? "Pay" : "Receipt"
                      }}</v-btn>
                  </td>
                </tr>
              </table>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="historyDialoge = false"
          >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="displayNumberModal" scrollable width="20%">
      <v-card>
        <v-card-title class="headline">Display Number</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="mt-4">
                <v-text-field
                    v-model="displayNumber"
                    :label="`Display Number*`"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[(v) => !!v || 'Display Number is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="displayNumberModal = false"
          >Close</v-btn
          >
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="saveDisplayNumber()"
          >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="endDateModal" scrollable width="20%">
      <v-card>
        <v-card-title class="headline">End date</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="mt-4">
                <date-field
                    v-model="endDate"
                    label="End Date"
                    :rules="[(v) => !!v || 'End Date is required']"
                ></date-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="endDateModal = false"
          >Close</v-btn
          >
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="saveEndDate()"
          >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showMemberShipCard" scrollable width="800">
      <FlipCard>
        <template slot="front">
          <v-card
              class="pa-5 member-card"
              :style="{ backgroundImage: 'url(' + membershipCardImage + ')' }"
          >
            <!-- <v-card
              class="mx-auto"
              style="border-radius: 29px; opacity: 0.96"
              max-width="800"
            > -->
            <!-- align="center" justify="center" -->
            <v-container fill-height fluid>
              <v-row>
                <v-col md="6" style="text-align: center" class="pa-13 pb-12">
                  <v-avatar
                      color="primary"
                      size="170"
                      style="text-align: center"
                  >
                    <v-img
                        class="elevation-6"
                        alt=""
                        :src="
                        selectedMemberDetails
                          ? s3BucketURL + selectedMemberDetails.profile_image
                          : null
                      "
                    ></v-img
                    ></v-avatar>
                </v-col>
                <v-col md="1"></v-col>

                <v-col md="5" class="pt-0 pr-0">
                  <v-img
                      width="100"
                      alt=""
                      style="margin-left: auto"
                      :src="
                      selectedMemberDetails
                        ? s3BucketURL + this.$store.getters.venueInfo.logo
                        : null
                    "
                  ></v-img>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="2">
                  <span> NAME :</span>
                </v-col>
                <v-col md="5">
                  <span>
                    {{
                      selectedMemberDetails
                          ? selectedMemberDetails.first_name
                          : null
                    }}
                    {{
                      selectedMemberDetails
                          ? selectedMemberDetails.last_name
                          : null
                    }}
                  </span>
                </v-col>
                <v-col md="2" class="pl-0 pr-0">
                  <span> MEMBERSHIP # :</span>
                </v-col>
                <v-col md="3">
                  <span>
                    {{
                      selectedMemberDetails
                          ? selectedMemberDetails.display_card_number
                          : null
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="2">
                  <span> VALIDITY :</span>
                </v-col>
                <v-col md="5">
                  <span>
                    {{
                      selectedMemberDetails
                          ? selectedMemberDetails.start_date
                          : null | dayFormat
                    }}
                    TO
                    {{
                      selectedMemberDetails
                          ? selectedMemberDetails.end_date
                          : null | dayFormat
                    }}
                  </span>
                </v-col>
                <v-col md="2" class="pl-0">
                  <span> TYPE :</span>
                </v-col>
                <v-col md="3">
                  <span>
                    {{ membershipName ? membershipName : null }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
            <!-- </v-card> -->
          </v-card>
        </template>
        <template slot="back">
          <v-card
              class="pa-5 member-card"
              style="height: 100%"
              :style="{ backgroundImage: 'url(' + membershipCardImage + ')' }"
          >
            <!-- <v-card
              class="mx-auto"
              style="border-radius: 29px; height: 100%"
              dark
              max-width="700"
            > -->
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col style="text-align: center" class="pa-15">
                  <v-img
                      width="150"
                      alt=""
                      style="margin-right: auto; margin-left: auto"
                      :src="
                      selectedMemberDetails
                        ? s3BucketURL + this.$store.getters.venueInfo.logo
                        : null
                    "
                  ></v-img>
                </v-col>
              </v-row>
            </v-container>
            <!-- </v-card> -->
          </v-card>
        </template>
      </FlipCard>
    </v-dialog>

    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>

    <order-details
        :id="orderId"
        :ids="orderIds"
        :invId="invId"
        :invIds="invIds"
        @close="closeMainComponent"
        @paymentDone="getMembersList(), $emit('reload')"
    ></order-details>
    <customer-model
        v-bind="userModel"
        @confirm="getFilterData"
        @close="userModel.userID = null"
        @updated="getMembersList()"
        :isPermissionCheck="
        checkWritePermission($modules.memberships.management.slug)
      "
    />
    <MembershipFreezePopup
        v-if="freezeProduct"
        :mfdialog="mfdialog"
        :freezeData="freezeData"
        :freezeProduct="freezeProduct"
        @close="closeMfDialoag"
        @saveMfData="saveMfData"
    />
    <MembershipUpgrade
        :upgradeModal="upgradeModal"
        :id="memberId"
        @close="closeUpgradeModal"
        @changeIdProofTypeId="changeIdProofTypeId"
    />

    <AddAdditionalMember
        :data="addAdditionalData"
        :modal="addAdditionalDialog"
        @close="closeAdditionalMemberDialog"
        @save="saveAdditionalMember"
    />
  </div>
</template>

<script>
import moment from "moment";
import OrderDetails from "@/components/Order/OrderDetails.vue";
import CustomerModel from "../../Clients/Customer/CustomerModel.vue";
import bookingFields from "@/mixins/bookingFieldValidation";
import FlipCard from "./MembershipCard.vue";
import MembershipFreezePopup from "@/components/Membership/MembershipFreezePopup.vue";
import MembershipUpgrade from "@/components/Membership/MembershipUpgrade.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import DateOfBirthField from "@/components/Fields/DateOfBirthField.vue";
import AddIcon from "@/assets/images/misc/plus-icon.svg";
import AddAdditionalMember from "@/components/Membership/AddAdditionalMember.vue";
export default {
  name: "MembershipDetails",
  props: {
    membershipId: { type: Number, default: 0 },
    membershipStatus: { type: Number, default: 0 },
    membershipName: { type: String, default: "" },
    description: { type: String, default: "" },
    durations: { type: Array, default: () => {} },
    packages: { type: Array, default: () => {} },
    //membershipTagsList: {},
    membershipCardImage: {
      type: String,
      default: require("@/assets/images/default_images/membership_card.png"),
    },
  },
  components: {
    AddIcon,
    DateOfBirthField,
    ImageUploader,
     ExportIcon, SvgIcon,
    OrderDetails,
    CustomerModel,
    FlipCard,
    MembershipFreezePopup,
    MembershipUpgrade,
    AddAdditionalMember,
  },
  data() {
    return {
      upgradeModal: false,
      memberId: null,
      disableStatus: ["Expired", "Cancelled", "Deleted", "Refund", "Void"],
      endDate: null,
      endDateModal: false,
      displayNumberModal: false,
      displayNumber: null,
      editingMember: null,
      renewOrEdit: false,
      orderIds: null,
      invIds: null,
      invId: null,
      perPage: 10,
      historyDialoge: false,
      plans: [],
      member: {
        package: null,
        promotion_code: null,
        plan: null,
        customers: [
          {
            first_name: null,
            last_name: null,
            email: null,
            gender: null,
            country_id: null,
            dob: null,
            id_proof: null,
            id_proof_type_id: null,
            is_primary: 1,
            customer_tag: null,
          },
        ],
      },
      editFlag: false,
      packageSelected: {},
      discounted_price: 0,
      discount_applied: false,
      promotion: false,
      paidFlag: false,
      package: [],
      venue_service_id: null,
      searchParams: {},
      currentPage: 0,
      pageSize: 10,
      items: [],
      names: [],
      tags: [],
      tag: null,
      name: null,
      country_id: null,
      phones: [],
      phone: null,
      emails: [],
      email: null,
      isLoading: false,
      ageMenu: false,
      totalPages: 1,
      valid: false,
      membershipDialoge: false,
      page: 1,
      date: null,
      menu: false,
      ptDialoge: false,
      membersList: [],
      existingUser: false,
      userModel: { userID: null, type: "details" },
      historyData: [],
      orderId: null,
      today: moment().format("YYYY-MM-DD"),
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      pay_dialog: false,
      product: [],
      productType: "Membership",
      orderBy: "start_date",
      orderDir: "DESC",
      isEmiratesIdCheck: false,
      showMemberShipCard: false,
      selectedMemberDetails: null,
      isEnableApprovalProcess: false,
      mfdialog: false,
      freezeData: null,
      freezeProduct: null,
      addAdditionalData : null,
      addAdditionalDialog : false
    };
  },
  mixins: [bookingFields],
  mounted() {
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags", "normal");
    }
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getIdProofTypes.status == false) {
      this.$store.dispatch("loadIDProofTypes");
    }
    if (!this.$store.getters.configurationStatus("Membership")) {
      this.$store.dispatch("loadConfigurations", "Membership").then((res) => {
        this.setDocumentFields();
        if (res.data.data && res.data.data.membership_configurations) {
          if (res.data.data.membership_freeze_product) {
            this.freezeProduct = res.data.data.membership_freeze_product;
          }
          this.isEnableApprovalProcess = Boolean(
              res.data.data.membership_configurations.is_enable_approval_process
          );
        }
        if (res.data.data && res.data.data.membership_card_configuration) {
          this.$emit(
              "changeImage",
              this.s3BucketURL +
              res.data.data.membership_card_configuration.image_path
          );
          // this.membershipCardImage =
          // this.s3BucketURL +
          //res.data.data.membership_card_configuration.image_path;

          this.$nextTick(() => {
            this.$forceUpdate;
          });
        }
        this.setFieldConfigurations();
      });
    } else {
      this.$emit(
          "changeImage",
          this.s3BucketURL +
          this.$store.getters.getMembershipCardByProduct("Membership")
              .image_path
      );
      // this.membershipCardImage =
      // this.s3BucketURL +
      //this.$store.getters.getMembershipCardByProduct("Membership").image_path;
      this.$nextTick(() => {
        this.$forceUpdate;
      });
      this.$nextTick(() => {
        this.isEnableApprovalProcess = Boolean(
            this.$store.getters.getMembershipConfigByProduct("Membership")
                .is_enable_approval_process
        );
        this.$forceUpdate;
      });
    }
    if (!this.$store.getters.getMembershipTagsByProduct) {
      this.$store.dispatch("loadConfigurations");
    }
    this.checkPermission = this.checkExportPermission(
        this.$modules.salesTeam.dashboard.slug
    );
    if (this.checkPermission) {
      this.$store.dispatch("loadSalesTeams", "Membership");
    }
    this.setFieldConfigurations();
    this.setDocumentFields();
    if (!this.freezeProduct) {
      this.getMembeshipFreezeProduct();
    }
    this.getMembersList();
  },

  computed: {
    allTags() {
      return this.$store.getters.getTags.data;
    },
    countries() {
      return this.$store.getters.getCountries.data;
    },
    idProofTypes() {
      return this.$store.getters.getIdProofTypes.data;
    },
    promotions() {
      return this.$store.getters.getPromotions.data;
    },
    membershipTags() {
      return this.$store.getters.getMembershipTagsByProduct;
    },
    salesTeams() {
      return this.$store.getters.getSalesTeams.data;
    },
    allowAdditionalMember() {
      // member.package != null && (member.package.members_limit > member.customers.length || member.package.additional_price != null)

      if (this.member.package != null && this.member.status != 4) {
        if (
            this.member.package.members_limit > this.member.customers.length ||
            this.member.package.additional_price != null
        ) {
          return true;
        } else {
          return false;
        }
      } else if (this.member.package != null && this.member.status == 4) {
        if (this.member.package.members_limit > this.member.customers.length) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
  },

  watch: {
    membershipDialoge(val) {
      if (val == false) {
        this.setDocumentFields();
      }
    },

    membershipId(val) {
      if (val != null) {
        this.getMembersList();
      }
    },

    page() {
      this.getMembersList();
    },
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    phone(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("mobile", val);
    },
    email(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("email", val);
    },
    tag(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("tag", val);
    },
  },

  methods: {
    closeMainComponent() {
      this.orderId = null;
      this.orderIds = null;
      this.invIds = null;
      this.invId = null;
    },
    getExpiryDate(member) {
      this.showLoader("Fetching details");

      this.$http
          .get(`venues/memberships/members/expiry-date/${member.member_id}`)
          .then((response) => {
            this.hideLoader();
            if (
                response.status == 200 &&
                response.data.status == true &&
                response.data.data !== ""
            ) {
              this.endDateModal = true;
              this.editingMember = member.member_id;
              this.endDate = response.data.data;
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    saveEndDate() {
      let data = {
        end_date: this.endDate,
      };
      this.$http
          .post(
              `venues/memberships/members/expiry-date/${this.editingMember}`,
              data
          )
          .then((response) => {
            this.hideLoader();
            if (
                response.status == 200 &&
                response.data.status == true &&
                response.data.data !== ""
            ) {
              this.endDateModal = false;
              this.editingMember = null;
              this.endDate = null;
              this.$forceUpdate();
              this.getMembersList();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    getDisplayNumber(member) {
      this.showLoader("Fetching details");

      this.$http
          .get(
              `venues/memberships/members/get-display-number/${member.member_id}`
          )
          .then((response) => {
            this.hideLoader();
            if (
                response.status == 200 &&
                response.data.status == true &&
                response.data.data !== ""
            ) {
              this.displayNumberModal = true;
              this.editingMember = member.member_id;
              this.displayNumber = response.data.data;
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    saveDisplayNumber() {
      let data = {
        member_id: this.editingMember,
        displayNumber: this.displayNumber,
      };
      this.$http
          .post(`venues/memberships/members/update-display-number`, data)
          .then((response) => {
            this.hideLoader();
            if (
                response.status == 200 &&
                response.data.status == true &&
                response.data.data !== ""
            ) {
              this.displayNumberModal = false;
              this.editingMember = null;
              this.displayNumber = null;
              this.$forceUpdate();
              this.getMembersList();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    getOrderDetails(data1) {
      this.showLoader("Fetching details");
      if (data1.invoice_generated && data1.invoice_id) {
        this.$http
            .get(
                `venues/memberships/members/get-parent-invoice/${data1.invoice_id}`
            )
            .then((response) => {
              this.hideLoader();
              if (
                  response.status == 200 &&
                  response.data.status == true &&
                  response.data.data !== ""
              ) {
                let data = response.data.data;
                this.invIds = [data1.invoice_id];
                data.split(",").forEach((id) => {
                  this.invIds.push(Number(id));
                });
                this.$forceUpdate();
              } else {
                this.invId = data1.invoice_id;
              }
            })
            .catch((error) => {
              this.hideLoader();
              this.errorChecker(error);
            });
      } else {
        this.$http
            .get(`venues/memberships/members/get-parent-order/${data1.order_id}`)
            .then((response) => {
              this.hideLoader();
              if (
                  response.status == 200 &&
                  response.data.status == true &&
                  response.data.data !== ""
              ) {
                let data = response.data.data;
                this.orderIds = [data1.order_id];
                data.split(",").forEach((id) => {
                  this.orderIds.push(Number(id));
                });
                this.$forceUpdate();
              } else {
                this.orderId = data1.order_id;
              }
            })
            .catch((error) => {
              this.hideLoader();
              this.errorChecker(error);
            });
      }
    },
    calculateClass(index) {
      let endClass = "md-table-row member-all";
      if (index == 0) {
        endClass += " member-parent";
      }
      if (
          index > 0 &&
          index <= this.membersList.length - 2 &&
          this.membersList[index].order_id == this.membersList[index + 1].order_id
      ) {
        endClass += " member-parent";
      }
      if (
          index > 0 &&
          index <= this.membersList.length - 1 &&
          this.membersList[index].order_id == this.membersList[index - 1].order_id
      ) {
        endClass += " member-child";
      }
      return endClass;
    },
    setCardData(data, index = null) {
      this.setCustomerData(data, index);
      // if (!data.customer_id) {
      //   this.$set(this.member.customers[0], "customer_id", null);
      // }

      // if (!data.name && data.first_name) {
      //   this.$set(this.member.customers[0], "name", data.first_name);
      // }

      // if (!data.customer_id && this.member.customers[0].name != data.name) {
      //   this.$set(this.member.customers[0], "mobile", null);
      //   this.member.customers[0].search = null;
      //   this.member.customers[0].nameSearch = null;
      //   this.$set(this.member.customers[0], "email", null);
      //   this.$set(this.member.customers[0], "gender", null);
      //   this.$set(this.member.customers[0], "name", null);
      //   this.$set(this.member.customers[0], "customer_id", null);
      //   this.$set(this.member.customers[0], "first_name", null);
      //   this.$set(this.member.customers[0], "image_path", null);
      //   this.$set(this.member.customers[0], "dob", null);
      //   this.$set(this.member.customers[0], "country_id", null);
      //   this.$set(this.member.customers[0], "last_name", null);
      //   this.$set(this.member.customers[0], "opt_marketing", false);
      //   this.$forceUpdate();
      // }

      // if (data.mobile) {
      //   this.isEmiratesIdCheck = true;
      //   this.$set(this.member.customers[0], "mobile", data.mobile);
      // }
      // if (data.email) this.$set(this.member.customers[0], "email", data.email);
      // if (data.country_id) {
      //   this.$set(this.member.customers[0], "country_id", data.country_id);
      // } else {
      //   this.$set(this.member.customers[0], "country_id", null);
      // }
      // if (data.country_id) {
      //   this.$set(
      //     this.member.customers[0],
      //     "id_proof_type_id",
      //     data.id_proof_type_id
      //   );
      // }

      // if (data.id_proof_number) {
      //   this.$set(
      //     this.member.customers[0],
      //     "id_proof_number",
      //     data.id_proof_number
      //   );
      // }

      // if (data.gender) {
      //   this.$set(this.member.customers[0], "gender", data.gender);
      // } else {
      //   this.$set(this.member.customers[0], "gender", null);
      // }
      // if (data.dob) {
      //   this.$set(this.member.customers[0], "dob", data.dob);
      // } else {
      //   this.$set(this.member.customers[0], "dob", null);
      // }

      // if (data.image) {
      //   this.$set(this.member.customers[0], "image", data.image);
      // }

      // if (data.name) this.$set(this.member.customers[0], "name", data.name);
      // if (data.last_name) {
      //   this.$set(this.member.customers[0], "last_name", data.last_name);
      // } else {
      //   this.$set(this.member.customers[0], "last_name", null);
      // }
      // if (data.first_name)
      //   this.$set(this.member.customers[0], "first_name", data.first_name);
      // if (data.customer_id)
      //   this.$set(this.member.customers[0], "customer_id", data.customer_id);
      // if (data.image_path) {
      //   this.$set(this.member.customers[0], "image_path", data.image_path);
      // } else {
      //   this.$set(this.member.customers[0], "image_path", null);
      // }
      // if (data.opt_marketing) {
      //   if (data.opt_marketing == 1) {
      //     this.$set(this.member.customers[0], "opt_marketing", true);
      //   } else {
      //     this.$set(this.member.customers[0], "opt_marketing", false);
      //   }
      // }
      // this.$forceUpdate();
    },
    setCustomerData(data, index) {
      if (data.isEmiratesIdCheck) {
        this.isEmiratesIdCheck = true;
      }
      if (data.mobile && data.first_name && data.customer_id) {
        this.isEmiratesIdCheck = false;
      }
      if (!data.customer_id) {
        this.$set(this.member.customers[index], "customer_id", null);
      }

      if (!data.name && data.first_name) {
        this.$set(this.member.customers[index], "name", data.first_name);
      }
      if (!data.customer_tag) {
        this.$set(this.member.customers[index], "customer_tag", null);
      }

      if (
          this.member.customers[index].customer_id &&
          !data.customer_id &&
          this.member.customers[index].name != data.name &&
          this.member.customers[index].mobile != data.mobile
      ) {
        this.$set(this.member.customers[index], "mobile", null);
        this.member.customers[index].search = null;
        this.member.customers[index].nameSearch = null;
        this.$set(this.member.customers[index], "email", null);
        this.$set(this.member.customers[index], "gender", null);
        this.$set(this.member.customers[index], "name", null);
        this.$set(this.member.customers[index], "customer_id", null);
        this.$set(this.member.customers[index], "first_name", null);
        this.$set(this.member.customers[index], "image_path", null);
        this.$set(this.member.customers[index], "dob", null);
        this.$set(this.member.customers[index], "country_id", null);
        this.$set(this.member.customers[index], "last_name", null);
        this.$set(this.member.customers[index], "opt_marketing", null);
        this.$set(this.bookingForm, "id_proof_type_id", null);
        this.$set(this.bookingForm, "id_proof_number", null);
        this.$set(this.bookingForm, "id_proof_path", null);
        this.$set(this.member.customers[index], "customer_tag", null);

        this.$forceUpdate();
      }

      if (data.mobile) {
        this.$set(this.member.customers[index], "mobile", data.mobile);
      }
      if (data.customer_tag) {
        this.$set(
            this.member.customers[index],
            "customer_tag",
            data.customer_tag
        );
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.member.customers[index], "customer_tag", null);
        }
      }
      if (data.email) {
        this.$set(this.member.customers[index], "email", data.email);
      }
      if (data.country_id) {
        this.$set(this.member.customers[index], "country_id", data.country_id);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.member.customers[index], "country_id", null);
        }
      }
      if (data.gender) {
        this.$set(this.member.customers[index], "gender", data.gender);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.member.customers[index], "gender", null);
        }
      }
      if (data.dob) {
        this.$set(this.member.customers[index], "dob", data.dob);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.member.customers[index], "dob", null);
        }
      }
      if (data.name) this.$set(this.member.customers[index], "name", data.name);
      if (data.last_name) {
        this.$set(this.member.customers[index], "last_name", data.last_name);
      } else {
        this.$set(this.member.customers[index], "last_name", null);
      }
      if (data.first_name)
        this.$set(this.member.customers[index], "first_name", data.first_name);
      if (data.customer_id)
        this.$set(
            this.member.customers[index],
            "customer_id",
            data.customer_id
        );
      if (data.image_path) {
        this.$set(this.member.customers[index], "image_path", data.image_path);
      } else {
        this.$set(this.member.customers[index], "image_path", null);
      }
      if (data.id_proof_type_id) {
        this.$set(
            this.member.customers[index],
            "id_proof_type_id",
            data.id_proof_type_id
        );
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.member.customers[index], "id_proof_type_id", null);
        }
      }

      if (data.id_proof_path) {
        this.$set(
            this.member.customers[index],
            "id_proof_path",
            data.id_proof_path
        );
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.member.customers[index], "id_proof_path", null);
        }
      }
      if (data.id_proof) {
        this.$set(this.member.customers[index], "id_proof", data.id_proof);
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.member.customers[index], "id_proof", null);
        }
      }
      if (data.opt_marketing) {
        this.$set(
            this.member.customers[index],
            "opt_marketing",
            data.opt_marketing
        );
      } else {
        this.$set(this.member.customers[index], "opt_marketing", null);
      }
      if (data.id_proof_number) {
        this.$set(
            this.member.customers[index],
            "id_proof_number",
            data.id_proof_number
        );
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.member.customers[index], "id_proof_number", null);
        }
      }
      if (data.customer_documents) {
        this.member.customers[index].customer_documents =
            data.customer_documents;
        if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_type_id
        ) {
          this.$set(
              this.member.customers[index],
              "id_proof_type_id",
              data.customer_documents[0].id_proof_type_id
          );
        }
        if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_number
        ) {
          this.$set(
              this.member.customers[index],
              "id_proof_number",
              data.customer_documents[0].id_proof_number
          );
        }
        if (
            data.customer_documents[0] &&
            data.customer_documents[0].id_proof_path
        ) {
          this.$set(
              this.member.customers[index],
              "id_proof_path",
              data.customer_documents[0].id_proof_path
          );
        }
      } else {
        if (data.customer_id) {
          this.member.customers[index].customer_documents = [];
        }
      }
      this.$forceUpdate();
    },
    getFilterUrl() {
      let url = `&page=${this.page}&per_page=${
          this.perPage != null ? this.perPage : 10
      }`;
      if (this.searchParams.name == "All") {
        this.searchParams.name = null;
      } else if (this.searchParams.name != null) {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.tag == "All") {
        delete this.searchParams.tag;
      } else if (this.searchParams.tag != null) {
        url += "&tag=" + this.searchParams.tag;
      }
      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (this.searchParams.email != null) {
        url += "&email=" + this.searchParams.email;
      }
      if (this.searchParams.mobile == "All") {
        delete this.searchParams.mobile;
      } else if (this.searchParams.mobile != null) {
        url += "&mobile=" + this.searchParams.mobile;
      }
      if (this.searchParams.membership_package_id == null) {
        delete this.searchParams.mobile;
      } else if (this.searchParams.membership_package_id != null) {
        url +=
            "&membership_package_id=" + this.searchParams.membership_package_id;
      }
      if (this.searchParams.display_card_number == null) {
        delete this.searchParams.display_card_number;
      } else if (this.searchParams.display_card_number != null) {
        url += "&display_card_number=" + this.searchParams.display_card_number;
      }
      if (this.searchParams.card_number == null) {
        delete this.searchParams.card_number;
      } else if (this.searchParams.card_number != null) {
        url += "&card_number=" + this.searchParams.card_number;
      }
      if (this.searchParams.start_date == null) {
        delete this.searchParams.start_date;
      } else if (this.searchParams.start_date != null) {
        url += "&start_date=" + this.searchParams.start_date;
      }
      if (this.searchParams.end_date == null) {
        delete this.searchParams.end_date;
      } else if (this.searchParams.end_date != null) {
        url += "&end_date=" + this.searchParams.end_date;
      }
      if (this.searchParams.status_id == null) {
        delete this.searchParams.status_id;
      } else if (this.searchParams.status_id != null) {
        url += "&status_id=" + this.searchParams.status_id;
      }
      url += `&order_by=${this.orderBy}&sort_order=${this.orderDir}`;
      return url;
    },
    getMembersList() {
      let url = this.getFilterUrl();
      this.$http
          .get(
              `venues/memberships/members?membership_id=${this.membershipId}${url}`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.membersList = response.data.data;
              this.membersList.forEach((ele, ii) => {
                this.membersList[ii].installments =
                    parseInt(ele.installments) + 1;
                this.membersList[ii].paid_installments =
                    parseInt(ele.paid_installments) +
                    (ele.order_status == "4" ? 1 : 0);
              });

              this.totalPages = response.data.total_pages;

              //this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      this.setFieldConfigurations();
    },
    filterSearch(type, val) {
      if (val == "All") return;
      this.isLoading = true;
      this.$http
          .get(`venues/memberships/members/filters?field=${type}&search=${val}`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.isLoading = false;
              if (type == "name") {
                this.names = response.data.data;
                this.names.unshift(val);
                this.names.unshift("All");
              } else if (type == "mobile") {
                this.phones = response.data.data;
                this.phones.unshift(val);
                this.phones.unshift("All");
              } else if (type == "email") {
                this.emails = response.data.data;
                this.emails.unshift(val);
                this.emails.unshift("All");
              } else if (type == "tag") {
                this.tags = response.data.data;
                this.tags.unshift(val);
                this.tags.unshift("All");
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    getFilterData() {
      this.page = 1;
      this.getMembersList();
    },
    addOrUpdateMember() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      var formData = new FormData();
      formData.append("membership_id", this.membershipId);
      formData.append("start_date", this.member.start_date);
      formData.append("end_date", this.member.end_date);
      formData.append("tag", this.member.tag);
      if (this.member.sales_team_id)
        formData.append("sales_team_id", this.member.sales_team_id);
      formData.append(
          "membership_package_id",
          this.member.membership_package_id
      );

      formData.append(
          "membership_package_plan_id",
          this.member.membership_package_plan_id
      );

      // if (this.member.contract != null) {
      //   formData.append("contract", this.member.contract);
      // }

      var document = [];
      this.documentFields.forEach((documentField) => {
        if (documentField.file || documentField.isEdit) {
          document.push(documentField);
        }
      });

      document.forEach((element, index) => {
        formData.append(`documents[${index}][name]`, element.name);
        formData.append(`documents[${index}][id]`, element.id);
        if (element.isEdit) {
          formData.append(`documents[${index}][file_path]`, element.file_path);
          formData.append(
              `documents[${index}][document_id]`,
              element.document_id
          );
          formData.append(
              `documents[${index}][original_file_name]`,
              element.original_file_name
          );
        }

        if (element.file) {
          formData.append(`documents[${index}][file]`, element.file);
        }
      });

      if (this.member.order_id != null) {
        formData.append("order_id", this.member.order_id);
      }

      if (this.member.promotion_code)
        formData.append("promotion_code", this.member.promotion_code);
      this.member.customers.forEach((customer, index) => {
        formData.append(`customers[${index}][mobile]`, customer.mobile);
        if (typeof customer.first_name !== "undefined") {
          formData.append(
              `customers[${index}][first_name]`,
              customer.first_name
          );
          if (customer.last_name) {
            formData.append(
                `customers[${index}][last_name]`,
                customer.last_name
            );
          }
        } else {
          if (customer.last_name) {
            formData.append(
                `customers[${index}][first_name]`,
                customer.last_name
            );
          }
        }

        if (customer.member_id != null) {
          formData.append(`customers[${index}][member_id]`, customer.member_id);
        }
        if (customer.customer_tag != null) {
          customer.customer_tag.forEach((tag, key) => {
            formData.append(
                `customers[${index}][customer_tag][${key}][id]`,
                tag.id
            );
            formData.append(
                `customers[${index}][customer_tag][${key}][name]`,
                tag.name
            );
          });
        }

        if (customer.customer_id != null) {
          formData.append(
              `customers[${index}][customer_id]`,
              customer.customer_id
          );
        }
        if (customer.email) {
          formData.append(`customers[${index}][email]`, customer.email);
        }
        if (customer.gender) {
          formData.append(`customers[${index}][gender]`, customer.gender);
        }
        if (customer.country_id) {
          formData.append(
              `customers[${index}][country_id]`,
              customer.country_id
          );
        }
        if (customer.dob) {
          formData.append(`customers[${index}][dob]`, customer.dob);
        }
        formData.append(`customers[${index}][is_primary]`, customer.is_primary);
        if (customer.id_proof_type_id) {
          formData.append(
              `customers[${index}][id_proof_type_id]`,
              customer.id_proof_type_id
          );
        }
        if (customer.id_proof) {
          formData.append(`customers[${index}][id_proof]`, customer.id_proof);
        }
        if (customer.opt_marketing) {
          formData.append(
              `customers[${index}][opt_marketing]`,
              customer.opt_marketing
          );
        } else {
          formData.append(`customers[${index}][opt_marketing]`, 0);
        }
        if (customer.card_number) {
          formData.append(
              `customers[${index}][card_number]`,
              customer.card_number
          );
        }
        if (customer.display_number) {
          formData.append(
              `customers[${index}][display_number]`,
              customer.display_number
          );
        }
        if (customer.image) {
          formData.append(`customers[${index}][profile_image]`, customer.image);
        }
        if (customer.id_proof_number) {
          formData.append(
              `customers[${index}][id_proof_number]`,
              customer.id_proof_number
          );
        }
      });
      this.member.membership_id = this.membershipId;
      this.$http
          .post(`venues/memberships/members/${this.member.operation}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          })
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              let data = response.data.data;
              if (data.status_id != 4) {
                //Not Paid
                this.orderId = response.data.data.id;
              }
              this.hideLoader();
              this.membershipDialoge = false;
              this.$emit("reload");
              this.getMembersList();
              this.showSuccess("Member added successfully");
              this.editFlag = false;
              this.renewOrEdit = false;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    editCustomer(userId) {
      this.userModel.type = "edit";
      this.userModel.userID = parseInt(userId);
    },

    viewMembershipCard(member) {
      this.selectedMemberDetails = member;
      this.showMemberShipCard = true;
    },

    sendMembershipCard(member) {
      this.showLoader("Sending mail....");
      this.$http
          .get(
              `venues/memberships/members/email_membership_card/${member.member_id}`,
              {
                responseType: "blob",
              }
          )
          .then(() => {
            this.hideLoader();
            this.showSuccess(`Membership card details send to ${member.email}`);
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    populateMembershipDetails(orderId, operation) {
      this.showLoader("Loading");
      this.editFlag = true;
      this.renewOrEdit = true;
      this.member = [];
      this.$http
          .get(`venues/memberships/members/details/${orderId}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              let pckage = this.packages.find(
                  (pkg) => pkg.id == data.membership_package_id
              );
              this.member = data;
              this.checkPackageHasPricing(data.membership_package_id);
              this.member.plan = data.membership_installment_id;
              this.member.membership_package_plan_id =
                  data.membership_installment_id;
              this.member.operation = operation;
              data.customers.forEach((customer, index) => {
                if (data.customer_id == customer.customer_id) {
                  this.member.customers[index].is_primary = "1";
                } else {
                  this.member.customers[index].is_primary = "0";
                }
                if (operation == "renew") {
                  this.member.customers[index].card_number = null;
                }
                this.member.customers[index].selected = customer.mobile;
              });

              if (data.documents) {
                data.documents.forEach((document) => {
                  this.documentFields.forEach((documentField) => {
                    if (documentField.id == document.felid_id) {
                      documentField.original_file_name =
                          document.original_file_name;
                      documentField.file_path = document.file_path;
                      documentField.document_id = document.id;
                      documentField.isEdit = true;
                    }
                  });
                });
              }

              if (operation == "renew") {
                this.renewOrEdit = false;
                this.member.start_date = null;
                this.member.end_date = null;
              }
              this.member.package = pckage;
              this.member.pricing = this.plans.find(
                  (x) => x.id === this.member.plan
              );

              this.product = [
                {
                  product_id: this.member.plan_data.product_id,
                  product_type_id: this.member.plan_data.product.product_type_id,
                  price: parseFloat(this.member.price),
                  name: this.member.package.name,
                  tax: this.member.tax,
                  membership_id: this.membershipId,
                  quantity: 1,
                },
              ];

              this.$store.dispatch("loadPromotions", {
                date: this.date,
                product_id: this.member.plan_data.product_id,
                membership_id: this.membershipId,
                product_type: "Membership",
              });

              this.membershipDialoge = true;
              if (data.status_id == 4) {
                this.paidFlag = true;
              }

              if (this.promotions && this.promotions.length == 0) {
                this.$store.dispatch("loadPromotions", {
                  date: this.date,
                  product_id: this.member.plan_data.product_id,
                  membership_id: this.membershipId,
                  product_type: "Membership",
                });
              }

              this.member.price = data.pre_tax_price;
              this.member.tax = data.tax;
              this.discounted_price = null;
              this.discount_applied = false;
              this.member.promotion_code = null;
              if (data.discount != null) {
                this.discounted_price = data.order_price;
                this.discount_applied = true;

                if (data.discount.promotion != null) {
                  this.member.promotion_code =
                      data.discount.promotion.promotion_code;
                }
              }
              this.$forceUpdate();
            }
            this.$forceUpdate();
          })
          .catch((error) => {
            this.errorChecker(error);
          })
          .finally(() => {
            this.hideLoader();
          });
    },
    getRenewHistory(userId) {
      this.$http
          .get(
              `venues/memberships/members/history/${userId}?membership_id=${this.membershipId}`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.historyDialoge = true;
              this.historyData = response.data.data;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    showReceipt(id) {
      this.orderId = id;
    },
    setEndDate(type) {
      let endDate = moment(this[type].start_date, "YYYY-MM-DD")
          .add(this[type].package.year, "years")
          .add(this[type].package.month, "months")
          .add(this[type].package.day, "days")
          .format("YYYY-MM-DD");
      this.$set(this[type], "end_date", endDate);
    },
    addMemberPopup() {
      this.member = {
        operation: "add",
        package_: null,
        tag: null,
        customers: [
          {
            name: null,
            email: null,
            gender: null,
            country_id: null,
            dob: null,
            id_proof: null,
            id_proof_type_id: null,
            is_primary: 1,
          },
        ],
      };
      this.existingUser = false;
      this.membershipDialoge = true;
      setTimeout(() => {
        this.$refs.form.resetValidation();
      });
    },

    addNewCustomer() {
      if (this.member.package == null) {
        this.showError("Select a package");
        return;
      }
      this.member.customers.push({
        first_name: null,
        last_name: null,
        email: null,
        gender: null,
        country_id: null,
        dob: null,
        id_proof: null,
        id_proof_type_id: null,
        is_primary: 0,
      });
      if (this.member.package.members_limit < this.member.customers.length) {
        this.member.price =
            this.member.price + parseFloat(this.member.package.additional_price);
      }
    },
    checkPackageHasPricing(id = null) {
      this.plans = [];
      if (!id) {
        id = this.member.membership_package_id;
      }
      if (!id) {
        id = this.member.package.id;
      }
      this.showLoader("Fetching data....");
      this.$http
          .get(`venues/memberships/package-pricing/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              let data = response.data.data;
              this.hideLoader();
              this.plans = data;
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    memberPackagePrice() {
      if (this.member.package != null && this.member.plan != null) {
        this.product = [];
        if (!this.editFlag) {
          this.discounted_price = 0;
          this.discount_applied = false;
          this.promotion = false;
          this.member.promotion_code = false;
        }
        let innerPlan = this.plans.find((x) => x.id === this.member.plan);
        this.member.membership_package_id = this.member.package.id;
        this.member.membership_package_plan_id = this.member.plan;
        this.member.price = parseFloat(innerPlan.price);
        this.member.tax = parseFloat(innerPlan.tax_amount);
        if (
            this.member.customers.length >
            parseInt(this.member.package.members_limit)
        )
          this.member.customers.splice(
              parseInt(this.member.package.members_limit)
          );
        if (this.member.start_date) {
          this.setEndDate("member");
        }
        this.product = [
          {
            product_id: innerPlan.product_id,
            product_type_id: innerPlan.product_type_id,
            price: parseFloat(innerPlan.price),
            name: innerPlan.name,
            tax: innerPlan.tax_amount,
            membership_id: this.membershipId,
            quantity: 1,
          },
        ];

        this.$store.dispatch("loadPromotions", {
          date: this.date,
          product_id: innerPlan.product_id,
          membership_id: this.membershipId,
          product_type: "Membership",
        });
      }
    },
    deleteCustomer(index) {
      this.confirmModel = {
        id: index.toString(),
        title: "Do you want to delete this customer type?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "customer",
      };
    },

    confirmActions(data) {
      if (data.type == "customer") {
        this.member.customers.splice(data.id, 1);
      }

      if (data.type == "Activate") {
        this.showLoader("Activating....");
        this.$http
            .get(`venues/memberships/members/active/${data.id}`, {
              responseType: "blob",
            })
            .then(() => {
              this.hideLoader();
              this.getMembersList();
              this.showSuccess(
                  "Member Activated. Membership card details send to email."
              );
            })
            .catch((error) => {
              this.errorChecker(error);
            });
      }

      if (data.type == "Deactivate") {
        this.showLoader("Deactivating....");
        this.$http
            .get(`venues/memberships/members/deactivate/${data.id}`, {
              responseType: "blob",
            })
            .then(() => {
              this.hideLoader();
              this.getMembersList();
              this.showSuccess("Member Deactivated.");
            })
            .catch((error) => {
              this.errorChecker(error);
            });
      }

      if (data.type == "Freeze") {
        this.mId = data.id;
        this.mfdialog = true;
      } else if (data.type == "UnFreeze") {
        this.mId = data.id;
        this.mfdialog = true;
      }
      if (data.type == "member_upgrade") {
        this.openUpgradeModal(data.id);
      }

      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    exportData() {
      this.showLoader("Wait...");
      let url = this.getFilterUrl();
      this.$http
          .get(
              `venues/memberships/members/export?membership_id=${this.membershipId}${url}`,
              {
                responseType: "blob",
              }
          )
          .then((response) => {
            if (response.status == 200) {
              this.downloadFile(response, "Members_Export_");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          })
          .finally(() => {
            this.hideLoader();
          });
    },
    verifyBenefit(type) {
      if (this.product.length == 0) {
        this.showError("Please add atleast one product");
        return;
      }
      let data = {
        products: [],
      };
      if (type == "promotion") {
        data.promotion_code = this.member.promotion_code;
        if (data.promotion_code == null) {
          this.clearBenefit();
          return;
        }
      }
      // if (this.registerForm.mobile) {
      //   data.mobile = this.registerForm.mobile;
      // }

      data.products = this.product;
      let url = "venues/benefits/verify";
      this.$http
          .post(url, data)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;

              if (data.discount) {
                this.discounted_price = data.total;
                this.discount_applied = true;
              }

              //this.data.products = data.products;
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    clearBenefit() {
      this.discount_applied = false;
      setTimeout(() => {
        this.$forceUpdate();
      });
    },

    documentUploadRule(field) {
      const rules = [];
      if (field.is_required && !field.isEdit) {
        const rule = (v) => !!v || "Document is required";
        rules.push(rule);
      }
      return rules;
    },
    sortColumn(type) {
      if (type == this.orderBy) {
        this.orderDir = this.orderDir == "ASC" ? "DESC" : "ASC";
      } else {
        this.orderDir = "DESC";
      }
      this.orderBy = type;
      this.getMembersList();
    },

    membershipApproval(member, type) {
      this.confirmModel = {
        id: member.member_id,
        title: `Do you want to ${type} this member?`,
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: type,
      };
    },

    membershipDeactivate(member, type) {
      this.confirmModel = {
        id: member.member_id,
        title: `Do you want to ${type} this member?`,
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: type,
      };
    },
    upgradeMembership(member) {
      this.confirmModel = {
        id: member.member_id,
        title: `Do you want to upgrade?`,
        description:
            "By clicking <b>Yes</b> you can confirm the operation. You will not be able to downgrade this member later. Do you need to continue your action ?",
        type: "member_upgrade",
      };
    },
    openUpgradeModal(id) {
      this.upgradeModal = true;
      this.memberId = id;
    },
    closeUpgradeModal(id = null) {
      this.upgradeModal = false;
      this.getMembersList();
      if (id && Number.isInteger(id)) {
        this.invId = id;
      }
      this.memberId = null;
    },

    membershipFreeze(member, type) {
      if (type == "Freeze") {
        type =
            member.status == "Active" && member.freeze_start
                ? "UnFreeze"
                : "Freeze";
      }
      this.freezeData = {
        member_id: member.member_id,
        order_id: member.order_id,
        start_date: member.start_date,
        end_date: member.end_date,
        freeze_start: member.freeze_start,
        freeze_end: member.freeze_end,
        type: type,
      };
      this.mfdialog = true;
    },

    addAdditionalMember(member){
      this.addAdditionalData = member;
      this.addAdditionalDialog = true;
    },

    closeAdditionalMemberDialog(){
      this.addAdditionalData = null;
      this.addAdditionalDialog = false;
    },

    saveAdditionalMember(data){

      this.showLoader("Saving members");
      this.$http
          .post(`venues/memberships/members/additional-member/add`,data)
          .then((response) => {
            this.hideLoader();
            if (
                response.status == 200 &&
                response.data.status == true &&
                response.data.data !== ""
            ) {
              this.getMembersList();
              this.invId = response.data.invoice_id;
              this.closeAdditionalMemberDialog();
            } else {
              this.closeAdditionalMemberDialog();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });

    },

    changeIdProofTypeId(data, index = null) {
      if (index !== null) {
        if (
            this.member.customers[index].customer_documents &&
            this.member.customers[index].customer_documents.length
        ) {
          let objType = this.member.customers[index].customer_documents.find(
              (x) => {
                return (
                    x.id_proof_type_id ===
                    this.member.customers[index].id_proof_type_id
                );
              }
          );
          if (typeof objType !== "undefined" && objType.id_proof_type_id) {
            this.member.customers[index].id_proof_number =
                objType.id_proof_number;
            this.member.customers[index].id_proof_path = objType.id_proof_path;
          } else {
            this.member.customers[index].id_proof_number = null;
            this.member.customers[index].id_proof_path = null;
          }
        } else {
          console.log("document length 0");
        }
      }
    },

    closeMfDialoag() {
      this.mfdialog = false;
      this.$set(this, "freezeData", {});
    },
    getMembeshipFreezeProduct() {
      this.showLoader("Fetching Product details");
      this.$http
          .get(`venues/memberships/members/freeze-product`)
          .then((response) => {
            this.hideLoader();
            if (
                response.status == 200 &&
                response.data.status == true &&
                response.data.data !== ""
            ) {
              this.freezeProduct = response.data.data;
            } else {
              this.freezeProduct = null;
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    saveMfData(data) {
      this.mfdialog = false;
      this.$set(this, "freezeData", {});
      if (data && data.type == "Freeze" && data.invoice_id) {
        this.invId = data.invoice_id;
      }
      this.getMembersList();
    },
    statusText(status_id) {
      switch(status_id) {
        case 1:
          return "Active";
        case 2:
          return "InActive";
        case 5:
          return "Unpaid";
        case 10:
          return "Expired";
        case 11:
          return "Pending";
        case 13:
          return "Cancelled";
        case 28:
          return "Freezed";
        default:
          return "Expired";
      }
    }
    
  },
};
</script>

<style scoped>
.v-select-list > .v-subheader {
  font-size: 0.95rem !important;
  font-weight: 600 !important;
  color: black;
}
tbody tr:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -moz-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  z-index: 1;
}
.personal-trainer_ic {
  background-image: url("../../../assets/images/nav_icon/personal-trainer-grey.png");
  padding: 10px;
  background-size: contain;
}
.member-card {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.member-card span {
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
}

.member-all {
  border: 2px solid #0000009e;
}

.member-parent {
  border-bottom: 0px;
}

.member-child {
  border-top: 0px;
}
</style>
